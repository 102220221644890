// --- Vendors ---
import Swiper, {Navigation,Pagination} from 'swiper';
Swiper.use([Navigation, Pagination]);

// --- Components ---
const homePage = {
    readyFn: function () {
        if ($('#photos .swiper-slide').length > 1) {
            const swiper = new Swiper('.swiper-container', {
                slidesPerView: 1,
                spaceBetween: 20,
            });
        }
    },
}
export { homePage };