// IMPORT VENDOR
import $ from 'jquery';
window.$ = window.jQuery = $;
import debounce from 'lodash/debounce';
import { Foundation } from 'foundation-sites/js/foundation.core';
Foundation.addToJquery($);
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
Foundation.plugin(MediaQuery, 'MediaQuery');
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
Foundation.plugin(DropdownMenu, 'DropdownMenu');
import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu';
Foundation.plugin(AccordionMenu, 'AccordionMenu');
import { Reveal } from 'foundation-sites/js/foundation.reveal';
Foundation.plugin(Reveal, 'Reveal');
import AOS from 'aos';

// IMPORT DES COMPONENTS
import { utils } from './components/_utils';
//import { bodyPaddingTop } from './components/_bodyPaddingTop';
import ScrollToLink from './components/_scrollTo';
import { header } from './components/header';

// Pages
import { homePage } from './pages/home';
import { galeriePage } from './pages/galerie';


// ----------------------------------- DOCUMENT READY -----------------------------------
// --------------------------------------------------------------------------------------
jQuery(function () {
    utils.checkDevice();
    jQuery(window).on('resize', debounce(function () {
        utils.checkDevice();
    }, 100));

    $(document).foundation();

    // Init scrollTo class
    let scrollTo = new ScrollToLink();
    scrollTo.init();


    // Padding top de la hauteur du header sur le <body>
    //bodyPaddingTop.init();


    function setCookie(cookieName, cookieValue, expirationDays) {
        // Crée une date d'expiration en fonction du nombre de jours spécifié
        var expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + expirationDays);

        // Convertit la date d'expiration en une chaîne de texte au format UTC
        var expires = "expires=" + expirationDate.toUTCString();

        // Définit le cookie en ajoutant son nom, sa valeur et la date d'expiration
        document.cookie = cookieName + "=" + cookieValue + "; " + expires + "; path=/";
    }

    $('#banniere .close').on('click', function () {
        $('#banniere').slideUp('fast');
        setCookie("banniere", "BanniereCache", 1);
    });

    function getCookie(cookieName) {
        var cookies = document.cookie.split(';');

        // Parcourt chaque cookie pour trouver le cookie spécifié par son nom
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim(); // Retire les espaces en début et fin de la chaîne

            // Vérifie si le cookie commence par le nom recherché
            if (cookie.indexOf(cookieName + '=') === 0) {
                // Si oui, extrait la valeur du cookie
                return cookie.substring(cookieName.length + 1); // +1 pour sauter le signe égal
            }
        }
    }
    var banniereCookie = getCookie("banniere");
    if (banniereCookie == "BanniereCache") {
        console.log("La valeur du cookie 'banniere' est : " + banniereCookie);
        $('#banniere').slideUp('fast');
    } else {
        console.log("Le cookie 'banniere' n'a pas été trouvé.");
    }

    header.readyFn();

    // Execute le script de la page dans laquelle on se trouve
    let bodyClassList = document.getElementsByTagName('body')[0].classList;
    switch (true) {
        case bodyClassList.contains('home'):
            homePage.readyFn();
            break;
        case bodyClassList.contains('page-template-page-galerie'):
            galeriePage.readyFn();
            break;
    }
});


// ----------------------------------- WINDOW LOAD --------------------------------------
// --------------------------------------------------------------------------------------
$(window).on('load', function () {
    document.body.classList.add('loaded');

    // Execute AOS
    setTimeout(() => {
        AOS.init({
            once: true,
            anchorPlacement: 'top-center',
            offset: -250,
            duration: 500
        });

        $('[data-aos]').one("transitionend webkitTransitionEnd oTransitionEnd", function () {
            $('[data-aos]').addClass('aos-end');
        });
    }, 100);

});