import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';


const galeriePage = {
    readyFn: function () {
        // make Masonry a jQuery plugin
        jQueryBridget('masonry', Masonry, $);
        
        const gallery = $('.gallery-wrapper').masonry({
            // options
            itemSelector: '.gallery-item',
            columnWidth: '.gallery-item',
            stamp: '.stamp',
            percentPosition: true,
            horizontalOrder: true
        });
    }
}

export { galeriePage };